define("discourse/plugins/discourse-gamification/discourse/controllers/admin-plugins-show-discourse-gamification-leaderboards-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-gamification/discourse/components/modal/recalculate-scores-form"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _I18n, _recalculateScoresForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    modal: (0, _service.inject)(),
    dialog: (0, _service.inject)(),
    toasts: (0, _service.inject)(),
    creatingNew: false,
    sortedLeaderboards(leaderboards) {
      return leaderboards?.sortBy("updatedAt").reverse() || [];
    },
    resetNewLeaderboard() {
      this.set("creatingNew", false);
    },
    destroyLeaderboard(leaderboard) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("gamification.leaderboard.confirm_destroy"),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${leaderboard.id}`, {
            type: "DELETE"
          }).then(() => {
            this.toasts.success({
              duration: 3000,
              data: {
                message: _I18n.default.t("gamification.leaderboard.delete_success")
              }
            });
            this.model.leaderboards.removeObject(leaderboard);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    recalculateScores() {
      this.modal.show(_recalculateScoresForm.default, {
        model: this.model
      });
    }
  }, [["method", "sortedLeaderboards", [(0, _decorators.default)("model.leaderboards.@each.updatedAt")]], ["method", "resetNewLeaderboard", [_object.action]], ["method", "destroyLeaderboard", [_object.action]], ["method", "recalculateScores", [_object.action]]]));
});